<template>
  <div class="c-product u-width-product">
    <div class="c-product-inner">
      <div class="c-product-image">
        <Picture
          v-if="model.image && model.image.url"
          :src="model.image"
          :resizer="model.imageResizer.name"
          :mobile="[200,550]"
          :options="options"
          :alt="model.imageAlt"
        />
      </div>
      <h1
        v-if="model.title"
        class="c-product-title"
        :style="model.titleFontSize && this.$mq === 'mobile' ? { fontSize: model.titleFontSize + 'px' } : {}"
        v-html="model.title"
      />
      <h2
        v-if="model.descriptionTitle"
        class="c-product-description-title"
        v-html="model.descriptionTitle"
      />
      <p
        v-if="model.descriptionText"
        class="c-product-description-text u-mb--s u-font-size-xlarge"
        v-html="model.descriptionText"
      />
    </div>
    <ProductSize :model="{ items: model.productSizes }" />
    <div class="c-product-inner--content u-flex">
      <ProductIngredients :model="model" />
      <ProductNutrition :model="model.nutrition" />
    </div>
  </div>
</template>

<script>
import Picture from '@/components/Picture.vue'
import ProductNutrition from '@/components/pages/partials/ProductNutrition.vue'
import ProductSize from '@/components/pages/partials/ProductSize.vue'
import ProductIngredients from './partials/ProductIngredients.vue'

export default {
  components: {
    ProductIngredients,
    Picture,
    ProductNutrition,
    ProductSize
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: { mode: 'max' }
    }
  }
}
</script>
